body{
    background-color: #202225;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */


.main{
    height: 100vh;
    width: 100vw;
    display: grid;
}

.chatWrapper{
    height: 90vh;
    width: 100vw;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.chat{
    height: 95%;
    width: 98%;
    background-color: #36393F;
    display: grid;
    place-items: center;
    overflow: hidden;
    border-radius: 25px;
}

.containerWrapper{
    height: 95%;
    width: 100%;
    overflow: auto;
}

.container{
    height: fit-content;
    width: fit-content;
    margin-left: 1em;
    margin-bottom: 1.3em;
}

.chatTextName{
    font-family: 'Uni Sans Bold';
    color: #9667FF;
    font-size: 120%;
    margin:0;
    padding:0;
}

.chatTextMessage{
    font-family: 'Uni Sans Regular';
    font-weight: lighter;
    color: white;
    font-size: 120%;
    margin:0;
    padding:0;
}