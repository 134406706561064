.navbar {
    height: 10vh;
    width: 100%;
    background-color: #2F3136;
    display: grid;
    grid-template-columns: 1fr 10fr;
    place-items: center;
}

.pwlogo {
    height: 10vh;
    width: auto;
}

.navbarGrid {
    display: flex;
    width: 95%;
    height: 100%;
    gap: 2em;
    align-items: center;
    justify-items: flex-end;
    justify-content: flex-end;
}

.whiteText {
    color: white;
    font-size: 2.2vh;
    font-family: 'Gilroy';
    font-weight: lighter;
    transition: 0.2s color ease;
    cursor: pointer;
}

.whiteText:hover {
    color: #9667FF;
}

.menuStack {
    color: white;
    font-size: 2.2vh;
    font-family: 'Gilroy';
    font-weight: lighter;
    transition: 0.2s color ease;
    cursor: pointer;
    width: 3.5em;
    text-align: center;
}

.menuStack:hover {
    color: #9667FF;
}